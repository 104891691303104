<div class="main_header_div">
  <div class="child">
      <h1 class="header_name ">Export Office Attendance</h1>
  </div>
  <div>
      <button class="close-button" (click)="matDialogClose()"><img src="assets/icons/close.png" alt=""></button>
  </div>
</div>
<hr>
<div class="container-fluid">
  <div class="row  main_container body-scroll">
      <div class="filter-container">
          <!-- Year selection -->
          <mat-form-field appearance="outline">
            <mat-label>Year</mat-label>
            <mat-select id="year" [(ngModel)]="selectedYear">
              <mat-option *ngFor="let year of availableYears" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
        
          <!-- Month selection -->
          <mat-form-field appearance="outline">
            <mat-label>Month</mat-label>
            <mat-select id="month" [(ngModel)]="selectedMonth">
              <mat-option *ngFor="let month of availableMonths; let i = index" [value]="i + 1">
                {{ month }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Base Location selection -->
          <mat-form-field appearance="outline">
            <mat-label>Base Location</mat-label>
            <mat-select id="month" [(ngModel)]="selectedBaseLocation">
              <mat-option *ngFor="let location of availableBaseLocation" [value]="location">
                {{ location }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        
  </div>
  <div class="row">
      <div class="col-md-12 ">
          <div class="main_button_div">
              <button mat-button [disabled]="generateDisabled" class="yes_button"
                  (click)="generateReport()">{{generateButtonText}}</button>
          </div>
      </div>
  </div>
</div>