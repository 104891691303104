<div>
  <div class="container">
    <div class="row mt-2">
    
      <!-- Search Bar -->
      <div class=" col-md-2 search-bar">
            <img class="col-md-3 icon" src="\assets\svg\search_bar.svg" alt="search" />
            <input type="search" class="search-input col-md-9" placeholder="Search"
              (input)="onSearchInput($event.target.value)" />
      </div>

      <div class="col-md-8  display-flex">
        <!-- Department Filter -->
       <div class="mat-form-field-capabilities col-md-3">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>
              <img src="\assets\svg\filter-icon.svg" />
              <span class="common-heading"> Capabilities</span>
            </mat-label>
            <mat-select [(ngModel)]="capabilitySearch" (selectionChange)="onFiltreChange()" multiple>
              <mat-option *ngFor="let capability of this.departments" [value]="capability"
                [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                [matTooltipDisabled]="!ifOverflow(cap)" matTooltip="{{ capability }}">
                <div class="options" #cap>{{ capability }}</div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Title filtre -->
        <div class="mat-form-field-title col-md-3">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>
                  <img src="\assets\svg\filter-icon.svg" />
                  <span class="common-heading"> Title</span>
                </mat-label>
                <mat-select [(ngModel)]="jobTitleSearch" (selectionChange)="onFiltreChange()" multiple>
                  <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle" [matTooltipPosition]="position.value"
                    [matTooltipClass]="position.value" [matTooltipDisabled]="!ifOverflow(t)" matTooltip=" {{ jobTitle }}">
                    <div class="options" #t>{{ jobTitle }}</div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>

        <!-- Base Location  filtre -->
        <div class="mat-form-field-base-location col-md-3">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>
                <img src="\assets\svg\filter-icon.svg" />
                <span class="common-heading"> Base location</span>
              </mat-label>
              <mat-select [(ngModel)]="baseLocation" (selectionChange)="onFiltreChange()">
                <mat-option *ngFor="let baseLocation of this.baseLocations" [value]="baseLocation" [matTooltipPosition]="position.value"
                  [matTooltipClass]="position.value" [matTooltipDisabled]="!ifOverflow(t)" matTooltip=" {{ baseLocation }}">
                  <div class="options" #t>{{baseLocation | titlecase}}</div>
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <!-- Date Picker -->
        <div class="from-date mat-form-field-date col-md-3">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label class="common-heading">Date <span class="contentRed"></span></mat-label>
              <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" autocomplete="off"
                [(ngModel)]="selectedDate" (dateChange)="onDateChange($event)" 
                [matDatepickerFilter]="dateFilter"/>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker
              [dateClass]="highlightedDateClass"></mat-datepicker>
            </mat-form-field>
        </div>
      </div>
      
      <div class="col-md-2 display-flex ">
        <!-- Bulk Import -->
        <div class="centered-content">
          <div [matTooltipPosition]="position.value"
            (click)="addBulkAttendanceRecords()" [matTooltipClass]="position.value" matTooltip="Bulk Import">
            <img class="img-class-bulk" src="assets/svg/bulk-import.svg" />
          </div>
        </div>

        <!-- Export -->
        <div class="download-employee-bulk" [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="Export Monthly Report" (click)="openExportOptions()">
              <img src="assets/svg/download.svg" />
        </div>
        <!--Pagination-->
        <div class="padding-top">
          <pagination-controls class="pagination-controls" 
              [maxSize]="5"
              [directionLinks]="true"
              (pageChange)="onPageChange($event)" 
              previousLabel="<"
              nextLabel=">">
          </pagination-controls>
        </div>
      </div>

      
   
    </div>
  </div>

  <div *ngIf="!isSpinnerActive" class="container">
    <div class="mt-3 row">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Name</span>
                </div>
              </th>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Capability</span>
                </div>
              </th>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Base Location</span>
                </div>
              </th>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Job Title</span>
                </div>
              </th>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Career Manager</span>
                </div>
              </th>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Hours</span>
                </div>
              </th>
              <th colspan="1.7">
                <div class="table-header-cell">
                  <span class="name">Monthly Hours</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let attendance of filteredAttendances| paginate : { itemsPerPage: this.pageSize, totalItems: this.totalPages * pageSize, currentPage: page }">

              <td colspan="1.7">
                <div class="row">
                  <div class="data">
                    <div *ngIf="!attendance.userThumbnailImage">
                      <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="Default Avatar" />
                    </div>
                    <div *ngIf="attendance.userThumbnailImage">
                      <img class="employee_img" [src]="attendance.userThumbnailImage" alt="Employee Image" />
                    </div>
                    <label class="name">{{ attendance.firstName }} {{ attendance.lastName }}</label>
                  </div>
                </div>
              </td>
              <td colspan="1.7">
                <div class="row">
                  <label class="data name">{{ (attendance.department | underScoreToSpace | titlecase).replace('Api', 'API')}} </label>
                </div>
              </td>
              <td colspan="1.7">
                <div class="row">
                  <label class="data name">{{ (attendance.base_location | titlecase)}} </label>
                </div>
              </td>
              <td colspan="1.7">
                <div class="row">
                  <label class="data name">{{ attendance.jobTitle | underScoreToSpace | titlecase }}</label>
                </div>
              </td>
              <td colspan="1.7">
                <div class="row">
                  <label class="data name">{{ attendance.careerManagerName }}</label>
                </div>
              </td>
              <td colspan="1.7">
                <label class="data name">
                  {{ formatMinutes(attendance.totalWorkedMinutes) }}
                </label>
              </td>
              <td colspan="1.7">
                <label class="data name">
                  {{ formatMinutes(attendance.totalWorkedMinutesForMonth) }}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </div>
  <div *ngIf="isSpinnerActive" class="spinner-div">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <label class="loading_label">Loading...</label>
  </div>
  <div *ngIf="!isSpinnerActive && attendances.length===0" class="spinner-div">
    <label class="loading_label">No data available for the date: {{selectedDate | date}}</label>
  </div>
  <div *ngIf="!isSpinnerActive && (filteredAttendances.length===0 && !(attendances.length===0))" class="spinner-div">
    <label class="loading_label">No matching records found for given filters, try searching something else...</label>
  </div>

</div>
