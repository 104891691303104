import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { TabNavigationService } from 'src/app/services/tab-navigation/tab-navigation.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})

export class SidebarMenuComponent implements OnInit {
  hasRoleBased: any;
  hasResourceAccessRights: boolean;
  hasEmployeeManagementRights: boolean;
  userid: any;
  flag = true;
  userSuperAdmin:boolean;
  hasResourceAccessRightsHr: boolean = false;
  hasGeoLocationAccessRights:boolean=false;
  @Output() sidebarToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  isExpandedSidebar: boolean = false;
  expandedMenu: boolean = false;

  constructor(
    public loginService: LoginService,
    public userDetail: UserDetailsService,
    public userDetailService: UserDetailsService,
    private authguardService: AuthGuardService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private tabNavigationService: TabNavigationService,
    public feedbackService: FeedbackService,
  ) {
    iconRegistry.addSvgIcon(
      'employee_profile',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/employee_profile.svg')
    );
  }

  ngOnInit(): void {
    this.userid = this.userDetailService.getUserDetails().id;
    if (this.userid.length < 7) {
      this.flag = false;
    }
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_ADMIN']);
    this.hasResourceAccessRights = this.userDetailService.hasRole([
      'ROLE_SUPER_ADMIN',
      'ROLE_PROJECT_ADMIN',
      'ROLE_ASSET_ADMIN',
      'ROLE_CLIENT_ADMIN',
    ]);
    this.hasEmployeeManagementRights = this.userDetailService.hasRole([
      'ROLE_ADMIN',
      'ROLE_SUPER_ADMIN',
      'ROLE_EMPLOYEE_ADMIN',
    ]);
    this.hasResourceAccessRightsHr = this.userDetailService.hasRole([
      'ROLE_HUMAN_RESOURCE',
      'ROLE_EMPLOYEE_ADMIN'
    ]);
      this.hasGeoLocationAccessRights=this.userDetailService.hasGeoRights(
        'INDIA'
    )
    this.userSuperAdmin=this.userDetailService.hasRole(['ROLE_SUPER_ADMIN'])

  }

  toggleSidebar() {
    this.isExpandedSidebar = !this.isExpandedSidebar;
    this.sidebarToggled.emit(this.isExpandedSidebar);
    if (!this.isExpandedSidebar) {
      this.expandedMenu = false;
    }
  }

  toggleMenu(){
    this.expandedMenu = !this.expandedMenu;
  }

  openExpandedMenu(){
    this.isExpandedSidebar = true;
    this.sidebarToggled.emit(this.isExpandedSidebar);
    this.expandedMenu = true;
  }

  openExpandedSidebar(){
    this.isExpandedSidebar = true;
    this.sidebarToggled.emit(this.isExpandedSidebar);
  }

  reloadProfile() {
    this.feedbackService.sendData(this.userDetailService.getUserDetails().id);
  }
  logOut() {
    this.authguardService.signOut();
  }
}
