import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OfficeAttendanceService } from 'src/app/services/office-attendance/office-attendance.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'app-office-attendance',
  templateUrl: './office-attendance.component.html',
  styleUrls: ['./office-attendance.component.scss']
})
export class OfficeAttendanceComponent implements OnInit {
attendanceForm: FormGroup;
isSpinner: any;

 officeAttendanceData;

totalDayHours = 8;
totalMonthHours = 40;
previousDateSet: boolean = false;
isEmployeeAdmin: boolean = false;
monthName = "";
selectedDate: any;
highlightedDates: string[] = [];

dateFilter = (date: Date | null): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const minDate = new Date(2025, 0, 1); // 01 Jan 2025
  return date !== null && date >= minDate && date < today; // Restrict before minDate and today
};

// Highlight specific dates with a class
highlightedDateClass = (date: Date): string => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return this.highlightedDates.includes(formattedDate) ? 'highlighted-date' : '';
};

  constructor(
    private fb: FormBuilder,
    private officeAttendanceService: OfficeAttendanceService,
    private userDetailsService: UserDetailsService,
    private toasterService: ToastrService
  ) { 
     this.attendanceForm = this.fb.group({
      attendanceDate: [this.getPreviousDay(), [Validators.required]] //Previous Day
        });
  }

  ngOnInit(): void {
    this.fetchAttendanceRecord();
    this.isEmployeeAdmin = this.userDetailsService.hasRole(['ROLE_EMPLOYEE_ADMIN']);

    this.attendanceForm.get('attendanceDate')?.valueChanges.subscribe((newDate) => {
      this.fetchAttendanceRecord();
    });
}

getPreviousDay(): Date {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);  // Get the previous day
  return yesterday;
}

  checkError(controlName: string, errorName: string): boolean {
    return this.attendanceForm.get(controlName)?.hasError(errorName) ?? false;
  }

  fetchAttendanceRecord(){

    this.selectedDate = this.attendanceForm.get('attendanceDate')?.value;

    this.monthName = new Intl.DateTimeFormat('en-US', { month: 'short' })
    .format(this.selectedDate);

    const formattedDate = new Date(Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate())).toISOString().split('T')[0];
    this.officeAttendanceService.getRecordsByDate(formattedDate).subscribe(
      (res) => {
        this.officeAttendanceData = res;
        if (this.officeAttendanceData?.attendanceDates) {
          this.highlightedDates = this.officeAttendanceData.attendanceDates;
        }
        else{
          this.highlightedDates = [];
        }
      },
      (error) => {
        this.officeAttendanceData = [];
        this.officeAttendanceData.attendanceLogs = [];
      }
    );
  }

  formatWorkedTime(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    return `${formattedHours} hrs ${formattedMinutes} mins`;
  }

  getNoDataMessage(): string {
    return this.officeAttendanceData.dataUploadedForTheDay
      ? `No swipes found for ${this.formatDate(this.selectedDate)}`
      : `Data for ${this.formatDate(this.selectedDate)} is unavailable`;
  }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

}
