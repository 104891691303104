<div class="main_header_div">
    <div class="child">
        <h1 class="header_name "><img class="header-icon mr-3" src="assets/icons/personalInfo.png"/> Personal Information
        </h1>
    </div>
    <div class="pr-3 pt-3">
        <img class="close-icon" (click)="closeDialog($event)" src="assets/icons/close.png"/>
    </div>
</div>
<hr />
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 form_main_div">
            <form [formGroup]="personalDetails">
                <div class="content-scroll">
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width" style="width:330px;">
                                <mat-label>Blood Group<span class="contentRed"></span></mat-label>
                                <mat-select formControlName="blood_group">
                                    <mat-option *ngFor="let bloodGroup of this.bloodgroup" [value]="bloodGroup">
                                        {{bloodGroup}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="checkError2('blood_group', 'required')">Required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Gender<span class="contentRed"></span></mat-label>
                                <mat-select formControlName="gender">
                                    <mat-option *ngFor="let gender of this.gender" [value]="gender">
                                        {{gender}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="checkError2('gender', 'required')">Required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">

                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width" style="width: 330px;">
                                <mat-label>Date of Birth <span class="contentRed"></span></mat-label>
                                <input matInput [matDatepicker]="picker1" placeholder="dd/mm/yyyy" [max]="today"
                                    formControlName="dob">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="checkError2('dob', 'required') && !checkError2('dob', 'matDatepickerParse')">Required</mat-error>
                                <mat-error *ngIf="checkError2('dob', 'matDatepickerParse')">Invalid Date</mat-error>

                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Place of Birth<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="place_of_birth" autocomplete="off">
                                <mat-error *ngIf="checkError2('place_of_birth', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError2('place_of_birth', 'pattern')">Invalid Place of
                                    Birth</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Nationality<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="nationality" autocomplete="off">
                                <mat-error *ngIf="checkError2('nationality', 'required')">Required</mat-error>
                                <mat-error *ngIf="checkError2('nationality', 'pattern')">Invalid Nationality
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">

                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Marital Status</mat-label>
                                <mat-select formControlName="marital_status">
                                    <mat-option *ngFor="let relationshipStatus of this.relationshipstatus"
                                        [value]="relationshipStatus">
                                        {{relationshipStatus}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="checkError2('marital_status', 'required')">Required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">

                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Date of Marriage</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" [max]="today"
                                    formControlName="dom"   [disabled]="['Unmarried', 'Other', 'Separated'].includes(maritalStatus)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="checkError2('dom', 'matDatepickerParse')">Invalid Date</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>