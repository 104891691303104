import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-birthdays-event',
  templateUrl: './birthdays-event.component.html',
  styleUrls: ['./birthdays-event.component.scss'],
})
export class BirthdaysEventComponent implements OnInit {
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  empDetail = [];
  Birthdaylist = [];
  Anniversarylist = [];
  userThumbnails = [];
  overall = 0;
  todaydate = new Date();

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.initiateBirthdayEvent();
  }

  initiateBirthdayEvent() {
    this.userService.getAllUsers().subscribe((res) => {
      this.userService.setUsersDto(res.body);
      this.empDetail = this.userService.usersDto;
      this.empDetail.forEach((element) => {
        if (element.dateOfBirth !== null) {
          let day2 = element.dateOfBirth;
          let date2 = new Date(day2);
          let date3 = new Date();

          const isTodayAnniversary =
            date2.getDate() == date3.getDate() &&
            date2.getMonth() == date3.getMonth();

          if (
            date2.getDate() >= date3.getDate() &&
            date2.getMonth() == date3.getMonth()
          ) {
            this.Anniversarylist.push({
              firstName: element.firstName,
              lastName: element.lastName,
              userImageThumbnail: element.userImageThumbnail,
              message: this.processDate(element.dateOfBirth),
              dateOfBirth: element.dateOfBirth,
              isImage: element.userImageThumbnail !== null,
              isToday: isTodayAnniversary,
            });
          }
        }
      });

      this.Anniversarylist.sort((a, b) => {
        const dateA = new Date(a.dateOfBirth);
        const dateB = new Date(b.dateOfBirth);
        return dateA.getDate() - dateB.getDate();
      });
    });

    if (this.userService.allUserData.length == 0) {
      this.userService.getAllUser();
    }
  }
  processDate(i) {
    let work_anniversary;
    if (i) {
      let dateOfBirth = new Date(i);
      let todaydate: Date = new Date();
      let diff = todaydate.getTime() - dateOfBirth.getTime();
      let days = diff / (1000 * 60 * 60 * 24);
      this.overall += days;
      function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so we add 1
        const year = date.getFullYear();
        return `${day}-${month}`;
      }

      if (
        dateOfBirth.getMonth() === todaydate.getMonth() &&
        dateOfBirth.getDate()=== todaydate.getDate()
      ) {
        // Format the date and assign it to work_anniversary
        work_anniversary = 'Today';
      } else if (dateOfBirth.getMonth() === todaydate.getMonth() &&
      dateOfBirth.getDate() > todaydate.getDate()
      ) {
        work_anniversary = `${formatDate(dateOfBirth)}-${todaydate.getFullYear()}`;
      } else {
        work_anniversary = '< 1 year';
      }
    }
    return work_anniversary;
  }

  getOrdinalIndicator(year: number): string {
    switch (year % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }

  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
}
