import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {
  BloodGroup,
  EventTabClosed,
  Gender,
  Relation,
  RelationshipStatus,
} from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent implements OnInit {
  personalDetails: FormGroup;
  // userDto = new UserDTO();
  family: FormArray;
  userDto: any;
  relationshipstatus: (string | RelationshipStatus)[] = [];
  bloodgroup = [];
  gender = [];
  maritalStatus: String;
  relation: (string | Relation)[] = [];
  today: Date = new Date();
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialogRef: MatDialogRef<PersonalInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }
  ngOnInit(): void {
    this.initiateFormGroup();
    this.setData();
    this.subscribeToMaritalStatusChanges();
  }
  initiateFormGroup() {
    this.personalDetails = this.fb.group({
      blood_group: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      place_of_birth: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z ]*$')],
      ],
      marital_status: ['', [Validators.required]],
      nationality: ['', [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
      dom: [{value: null, disabled: false}],
      familyDetails: this.fb.array([])
    });
  }
  setData() {
    this.userService.getUserPersonalInformationDetails(this.data).subscribe((res) => {
      this.userDto = res.body;
      this.setFormData();
      this.processEnumValuesToArray();
      this.subscribeToMaritalStatusChanges();

    });
  }
  setFormData() {
    this.personalDetails
      .get('dob')
      .setValue(
        this.userDto.dateOfBirth ? new Date(this.userDto.dateOfBirth) : ''
      );
    this.personalDetails
      .get('dom')
      .setValue(
        this.userDto.dateOfMarriage ? new Date(this.userDto.dateOfMarriage) : ''
      );
    this.personalDetails
      .get('nationality')
      .setValue(
        this.userDto.nationality);
    this.personalDetails
      .get('place_of_birth')
      .setValue(this.userDto.placeOfBirth);
    const bg = this.getEnumValueByEnumKey(BloodGroup, this.userDto.bloodGroup);
    this.personalDetails
      .get('blood_group')
      .setValue(this.userDto.bloodGroup ? bg : '');
    const ms = this.getEnumValueByEnumKey(
      RelationshipStatus,
      this.userDto.relationshipStatus
    );
    this.personalDetails
      .get('marital_status')
      .setValue(this.userDto.relationshipStatus ? ms : '');
    const gender = this.getEnumValueByEnumKey(
      Gender,
      this.userDto.gender
    );
    this.personalDetails
      .get('gender')
      .setValue(this.userDto.gender ? gender : '');
    this.family = this.personalDetails.get('familyDetails') as FormArray;
    const familyArray = this.personalDetails.get('familyDetails') as FormArray;
    familyArray.clear(); // Clear existing family members before adding new ones
    // Assuming this.userDto.family is an array of family members
    if (this.userDto.familyDetails && this.userDto.familyDetails.length > 0) {
      this.userDto.familyDetails.forEach(familyMember => {
        const newFamilyMember = this.fb.group({
          firstName: [familyMember.firstName,Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')],
          relation: [familyMember.relation]
        });
        familyArray.push(newFamilyMember);
      });
    } else {
      const newFamilyMember = this.fb.group({
        firstName: ['',Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')],
        relation: ['']
      });
      familyArray.push(newFamilyMember);
    }
  }
  processEnumValuesToArray() {
    for (const relationshipStatus of Object.values(RelationshipStatus)) {
      if (isNaN(Number(relationshipStatus))) { this.relationshipstatus.push(relationshipStatus); }
    }
    for (const bloodGroup of Object.values(BloodGroup)) {
      if (isNaN(Number(bloodGroup))) {
        this.bloodgroup.push(bloodGroup);
      }
    }
    for (const relation of Object.values(Relation)) {
      if (isNaN(Number(relation))) { this.relation.push(relation); }
    }
    for (const gender of Object.values(Gender)) {
      if (isNaN(Number(gender))) { this.gender.push(gender); }
    }
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  getauthoritiesKeyByEnumValueArray(myEnum, enumValue) {
    return Object.keys(myEnum).filter((k) => enumValue.includes(myEnum[k]));
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
  getauthorityValueByEnumKeyArray(myEnum, enumKeyArr) {
    return enumKeyArr ? enumKeyArr.map((k) => myEnum[k]) : '';
  }
  checkError2 = (controlName: string, errorName: string) => {
    return this.personalDetails.controls[controlName].hasError(errorName);
  };
  closeDialog(event: Event) {
    this.dialogRef.close({ event: EventTabClosed.Closed });
  }
  subscribeToMaritalStatusChanges() {
    this.personalDetails.get('marital_status').valueChanges.subscribe((value) => {
      const domControl = this.personalDetails.get('dom');
      const disabledStatuses = ["Unmarried", "Separated", "Other"];
    
      this.maritalStatus = value;
      disabledStatuses.includes(value) ? domControl.disable() : domControl.enable();
    });    

  }
  onSubmit() {
    if (this.personalDetails.valid) {
      this.userDto.bloodGroup = this.getEnumKeyByEnumValue(
        BloodGroup,
        this.personalDetails.get('blood_group').value
      );
      this.userDto.gender = this.getEnumKeyByEnumValue(
        Gender,
        this.personalDetails.get('gender').value
      );
      this.userDto.relationshipStatus = this.getEnumKeyByEnumValue(
        RelationshipStatus,
        this.personalDetails.get('marital_status').value
      );
      this.userDto.placeOfBirth =
        this.personalDetails.get('place_of_birth').value;
      this.userDto.dateOfBirth = this.personalDetails.get('dob').value;
      this.userDto.nationality = this.personalDetails.get('nationality').value;
      this.userDto.dateOfMarriage = this.personalDetails.get('dom').value;
      this.userDto.familyDetails = this.personalDetails.get('familyDetails').value;
      if (this.userDto) {
        this.userService.setUserPersonalInformationDetails(this.data, this.userDto).subscribe((res) => {
          this.toaster.success('Successfully Saved');
          this.dialogRef.close({ event: EventTabClosed.Closed });
        }, (e) => {
          this.toaster.error('Error');
        });
      }
    }
    else {
      this.toaster.error('Invalid Details');
    }
  }
}
