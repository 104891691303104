<div>
  <div class="search_main mt-0.5">
    <img src="assets/icons/search-black.png">
    <input
      class="search_input"
      [matAutocomplete]="userAuto"
      type="search"
      placeholder="Employee Search"
      [formControl]="userControl"
    />
    <mat-autocomplete
      #userAuto="matAutocomplete"
      (optionSelected)="selectUser($event.option.value)"
    >
    <mat-option
      *ngFor="let user of filteredUsers | async"
      [value]="user"
      (click)="selectUser(user)"
    >
      <span class="search-img">
        <img
          class="search_employee_image"
          src="assets/icons/default-avatar.jpg"
          alt=""
          *ngIf="!user?.userImageThumbnail"
        />
        <img
          class="search_employee_image"
          [src]="user.userImageThumbnail"
          alt=""
          *ngIf="user?.userImageThumbnail"
        />
        {{ user.firstName | titlecase }} {{ user.lastName | titlecase }} ({{ user.employeeId }})
      </span>
    </mat-option>

    </mat-autocomplete>
  </div>
</div>
