<div class="container-fluid main-container">
  <div class="main_header_div">
    <div class="sub-header">
      <div class="annual-fb-lbl">Annual Feedback</div>
      <div class="user-name">
        {{
        this.directReporteeId.directReporteeDetail.firstName +
        " " +
        this.directReporteeId.directReporteeDetail.lastName | titlecase
        }}
        ({{ this.directReporteeId.directReporteeDetail.employeeId | uppercase }})
      </div>
    </div>
    <div class="child_close_btn icon">
      <img class="close-icon-svg" (click)="closeDialog()" src="assets/icons/dashboard-icons/close-pink-icon.svg" />

    </div>
  </div>
  <hr class="" />
  <div style="padding: 0px 10px">
    <form [formGroup]="feedbackDetails">
      <div class="main-body-div">
        <div class="col-md-3 form-field">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Feedback Type</mat-label>
            <mat-select formControlName="feedbackType" (selectionChange)="loadForm($event)" [disabled]="!this.isEdit">
              <mat-option *ngFor="let form of this.projects" [value]="form">
                {{ form | underScoreToSpace | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="checkError">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 form-field" *ngIf="this.isEdit">
          <ng-template [ngIf]="!this.performancevisible">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Select a Project</mat-label>
              <mat-select formControlName="projectId" (selectionChange)="getProjectID($event.value)"
                [required]="!this.isRegularFeedback" [disabled]="!this.isEdit">
                <mat-option *ngFor="let pro of this.projectList" [value]="pro" [matTooltip]="pro" [matTooltipPosition]="position.value" [matTooltipClass]="position.value">
                  {{ pro | underScoreToSpace | titlecase }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="checkError">Required</mat-error>
            </mat-form-field>
          </ng-template>
          <ng-template [ngIf]="this.performancevisible">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Title</mat-label>
              <input formControlName="title" matInput autocomplete="off" [required]="this.isRegularFeedback"
                [disabled]="!this.isEdit" pattern="^[a-zA-Z ]*$" />
              <mat-error *ngIf="checkError('title', 'required')">Required</mat-error>
              <mat-error *ngIf="checkError('title', 'pattern')">Only alphabets are allowed</mat-error>
            </mat-form-field>
          </ng-template>
        </div>
        <div class="col-md-3 form-field" *ngIf="!this.isEdit">
          <ng-template [ngIf]="!this.performancevisible">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Select a Project</mat-label>
              <input formControlName="projectId" matInput autocomplete="off" readonly class="title" />
              <mat-error *ngIf="checkError">Required</mat-error>
            </mat-form-field>
          </ng-template>
          <ng-template [ngIf]="this.performancevisible">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Title</mat-label>
              <input formControlName="title" matInput autocomplete="off" readonly class="title" />
              <mat-error *ngIf="checkError">Required</mat-error>
            </mat-form-field>
          </ng-template>
        </div>
        <div class="col-md-3 form-field">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Year</mat-label>
            <mat-select formControlName="year" [disabled]="!this.isEdit">
              <mat-option *ngFor="let yearr of years" [value]="yearr">{{
                yearr
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="checkError">Required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2 form-field">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Weightage</mat-label>
            <input formControlName="groupWeightage" matInput autocomplete="off"
              (ngModelChange)="onWeightagetyped($event)" required />
            <mat-error *ngIf="
                this.feedbackDetails.get('groupWeightage').errors
                  ?.InvalidgroupWeightage
              ">
              {{ emailError ? emailError : "Required" }}
            </mat-error>
            <mat-error *ngIf="
                this.feedbackDetails.get('groupWeightage').errors?.required
              ">
              {{ emailError ? emailError : "Required" }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-1 mt-2 mb-2 form-field" *ngIf="this.directReporteeId.dataFeedback != null">
          <div (click)="deleteFeedbackGroup()" class="delete-btn">
            <img src="assets/icons/delete-pink.png" />
          </div>
        </div>
        <ng-template [ngIf]="selectedFormName === 'form3'"> </ng-template>
      </div>
    </form>
  </div>
  <hr />
  <div class="grp-btn">
    <button class="btn-blue" (click)="addRealtimeFeedback()">
      Fetch Real-time feedback
    </button>
    <div class="remaining-weightage">
      Available Weightage:
      <div *ngIf="(remainingWeight()<0.00)||(remainingWeight()>100.00)" class="red-weight">
        {{remainingWeight().toFixed(2)}}
      </div>
      <div *ngIf="(remainingWeight()>=0.00)||(remainingWeight()<=100.00)"class="blue-weight">
        {{remainingWeight().toFixed(2)}}
      </div>
    </div>
    <button class="btn-white" (click)="AddNewFeedback(false, '', 0)">
      Add Role
    </button>
  </div>

  <div class="table-body">
    <table class="user-list-table">
      <tr class="heading-table" style="background: linear-gradient(0deg, #cce1f7, #cce1f7)">
        <th class="col-md-3 common-class">Employee</th>
        <th class="col-md-3 common-class">Role</th>
        <th class="col-md-3 common-class">Weightage</th>
        <th class="col-md-3 common-class">Status</th>
        <th class="col-md-3 common-class"></th>
      </tr>
      <tr *ngFor="let feedback of opinion().controls; let i = index" class="hidden-content">
        <td class="col-md-3" *ngIf="feedbackDetails.value.feedbackArray[i].feedbackFrom">
          <label class="common-font-style">
            {{
              getFeedbackFrom(feedbackDetails.value.feedbackArray[i].feedbackFrom) | underScoreToSpace
            }}
          </label>
        </td>
        <td class="col-md-3" *ngIf="feedbackDetails.value.feedbackArray[i].feedbackRole">
          <label class="common-font-style">{{
            feedbackDetails.value.feedbackArray[i].feedbackRole
            | underScoreToSpace
            | titlecase
            }}</label>
        </td>
        <td class="col-md-3">
          <label class="common-font-style">{{
            feedbackDetails.value.feedbackArray[i].weightage
            }}</label>
        </td>
        <td class="col-md-3 Status">
          <label class="Status">{{
            feedbackDetails.value.feedbackArray[i].feedbackSubmissionStatus
            | underScoreToSpace
            | titlecase
            }}</label>
        </td>
        <td class="col-md-3" *ngIf="feedbackDetails.value.feedbackArray[i].feedbackRole">
          <div class="feedback-action-btns">
            <div (click)="AddNewFeedback(true, feedback, i)" *ngIf="
              feedbackDetails.value.feedbackArray[i].feedbackSubmissionStatus !== 'SUBMITTED' &&
              feedbackDetails.value.feedbackArray[i].feedbackSubmissionStatus !== 'DECLINED'">
              <img src="assets/icons/edit-rtf.png" class="icon"/>
            </div>
            <div (click)="removefeedbackopinion(feedback.value.id, i)"
              *ngIf="feedbackDetails.value.feedbackArray[i].feedbackSubmissionStatus !== 'SUBMITTED'">
              <img src="assets/icons/delete-pink.png" class="icon"/>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="div_submit_btn" style="padding: 0px 10px">
    <button mat-raised-button (click)="onSubmit()" 
    class="submit-btn" type="submit">
      Save
    </button>
  </div>
</div>
