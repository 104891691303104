<div class="row">

  <div class="col-xl-6 col-lg-6 col-md-12">
    <mat-card class="main-card personal-info left-extra-margin" *ngIf="this.personalInformationPresentCheck" [@slideUp]>
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets/icons/personalInfo.png">
          <span class="head ml-3">Personal Information</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editPersonalInformationDialog()" *ngIf="allowEditToOthers"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="persoal-information">
        <div class="information_mat_body mt-2 ">
          <div>
            <span class="information_heder">
              Blood Group </span>
            <br>
            <label *ngIf="(this.userDto?.bloodGroup) && (this.userDto?.bloodGroup?.length > 0)"
              class="blood_Group information_name mt-1 ">{{this.bloodGroup[this.userDto.bloodGroup]}}</label>
            <label *ngIf="!(this.userDto?.bloodGroup)" class="blood_Group information_name mt-1 ">-</label>
          </div>
        </div>
        <div class="information_mat_body mt-2">
          <div>
            <span class="information_heder">Gender</span>
            <br>
            <label *ngIf="(this.userDto?.gender) && (this.userDto?.gender?.length > 0)"
              class="information_name mt-1">{{this.userDto.gender |
              titlecase}}</label>
            <label *ngIf="!(this.userDto?.gender)" class="information_name mt-1">-</label>
          </div>
        </div>
        <div class="information_mat_body mt-2 ">
          <div>
            <span class="information_heder"> Date of Birth </span>
            <br>
            <label *ngIf="(this.userDto?.dateOfBirth) && (this.userDto?.dateOfBirth?.length > 0)"
              class="information_name mt-1">{{this.userDto.dateOfBirth
              | date : 'dd-MM-yyyy'}}</label>
            <label *ngIf="!(this.userDto?.dateOfBirth)" class="information_name mt-1">-</label>
          </div>
        </div>
        <div class="information_mat_body mt-2 ">
          <div>
            <span class="information_heder"> Place of Birth</span>
            <br>
            <label *ngIf="(this.userDto?.placeOfBirth) && (this.userDto?.placeOfBirth?.length > 0)"
              class="information_name  mt-1">{{
              this.userDto.placeOfBirth | titlecase }}</label>
            <label *ngIf="!(this.userDto?.placeOfBirth)" class="information_name  mt-1">-</label>
          </div>
        </div>
        <div class="information_mat_body mt-2">
          <div class="civil">
            <span class="information_heder">Nationality</span>
            <br>
            <label *ngIf="(this.userDto?.nationality)" class="information_name mt-1">{{this.userDto.nationality |
              titlecase}}</label>
            <label *ngIf="!(this.userDto?.nationality)" class="information_name mt-1">-</label>
          </div>
        </div>
        <div class="information_mat_body mt-2">
          <div class="civil">
            <span class="information_heder">Marital Status</span>
            <br>
            <label *ngIf="(this.userDto?.relationshipStatus) && (this.userDto?.relationshipStatus?.length > 0)"
              class="information_name mt-1">{{this.userDto.relationshipStatus | titlecase}}</label>
            <label *ngIf="!(this.userDto?.relationshipStatus)" class="information_name mt-1">-</label>

          </div>
        </div>
        <div class="information_mat_body mt-2">
          <div>
            <span class="information_heder">Date Of Marriage</span>
            <br>
            <label *ngIf="(this.userDto?.dateOfMarriage)" class="information_name mt-1">{{this.userDto.dateOfMarriage |
              date : 'dd-MM-yyyy'}}</label>
            <label *ngIf="!(this.userDto?.dateOfMarriage)" class="information_name mt-1">-</label>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="main-card left-extra-margin" *ngIf="!this.personalInformationPresentCheck" [@slideUp]>
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets/icons/personalInfo.png">
          <span class="head ml-3">Personal Information</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editPersonalInformationDialog()" *ngIf="allowEditToOthers"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-12">
    <mat-card class="main-card" *ngIf="(this.addresscurr?.length !== 0 || this.address?.length !== 0)" [@slideUp]>
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets/icons/currAddress.png">
          <span class="head ml-3">Address</span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editAddressDialog()" *ngIf="allowEditToOthers"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="addressfixed">
        <!-- Permanent Address -->
        <div class="row">
          <div class="col-xl-12">
            <div class="address_mat_body mt-2">
              <div class=" address-wrapper">
                <label class="address-title">Permanent Address</label>
                <div class="address-text">{{address.addressLineOne}} {{address.addressLineTwo}}, {{address.city |
                  titlecase}}, {{address.state | titlecase}}, {{address.country | titlecase}}-{{address.pincode}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Current Address -->
        <div class="row">
          <div class="col-xl-12">
            <div class="address_mat_body mt-2">
              <div class="address-wrapper">
                <label class="address-title">Current Address</label>
                <div class="address-text">{{addresscurr.addressLineOne}} {{addresscurr.addressLineTwo}},
                  {{addresscurr.city | titlecase}}, {{addresscurr.state | titlecase}}, {{addresscurr.country |
                  titlecase}}-{{addresscurr.pincode}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="main-card" *ngIf="this.addresscurr?.length == 0 && this.address.length == 0" [@slideUp]>
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets/icons/currAddress.png">
          <span class="head ml-3">Address </span>
        </div>
        <div class="mr-3">
          <mat-icon (click)="editAddressDialog()" *ngIf="allowEditToOthers"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
    <mat-card class="main-card" *ngIf="this.emergencycontactone?.length !== 0 || this.emergencycontacttwo?.length !== 0" [@slideUp]>
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets/icons/emergContact.png">
          <span class="head ml-3">Emergency Contacts</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editEmergencyContact()"><img class="edit_logo" src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="contentscroll">
        <div>
          <label class="emergency-title">Emergency Contact</label>
          <div class="emergency_mat_body ">
            <div>
              <label class="emergency_heder"> Relationship </label>:
              <label class="emergency_name1 ">{{emergencycontactone.relationship}}</label>
            </div>
          </div>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Name </span>:
              <label class="emergency_name1 ">{{emergencycontactone.name | titlecase}}</label>
            </div>
          </div>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Contact Number</span>:
              <label class="emergency_name1 ">{{emergencycontactone.phoneNumber}}</label>
            </div>
          </div>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Address</span>:
              <span class="try emergency_name1 mt-1">{{emergencycontactone.location | titlecase}}</span>
            </div>
          </div>
        </div>
        <div>
          <label class="emergency-title">Local Emergency Contact</label>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Relationship: </span>
              <label class="emergency_name1 ">{{emergencycontacttwo.relationship | titlecase}}</label>
            </div>
          </div>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Name: </span>
              <label class="emergency_name1 ">{{emergencycontacttwo.name | titlecase}}</label>
            </div>
          </div>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Contact Number:</span>
              <label class="emergency_name1 ">{{emergencycontacttwo.phoneNumber}}</label>
            </div>
          </div>
          <div class="emergency_mat_body ">
            <div>
              <span class="emergency_heder"> Address: </span>
              <span class="emergency_name1 ">{{emergencycontacttwo.location | titlecase}}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="main-card" *ngIf="this.emergencycontactone?.length == 0 && this.emergencycontacttwo?.length == 0" [@slideUp]>
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets/icons/emergContact.png" />
          <span class="head ml-3">Emergency Contacts</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editEmergencyContact()"><img class="edit_logo" src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>
</div>