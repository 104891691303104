import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  AbstractControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import {
  AuthoritiesConstants,
  Departments,
  JobTitles,
  ProbationPeriod,
  Gender,
  GeoLocation,
  EmployementType,
  RelationshipStatus,
  AuthoritiesConstantsForHr,
  AuthoritiesConstantsForEmployeeAdmin,
  AuthoritiesConstantsFoNewUser,
} from 'src/app/services/user/user-enum';
import { ProjectService } from 'src/app/services/project.service';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TitleService } from 'src/app/services/title-service';
import { addDays } from 'date-fns';
import { AddEmployeeProbationService } from 'src/app/services/getProbationPeriod/get-probation-period.service';
import { ToastrService } from 'ngx-toastr';
import { RestAPIService } from 'src/app/services/restAPI/rest-api.service';
import { UserUrlService } from 'src/app/services/restAPI/userURLs/user-url.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-add-new-employee-card',
  templateUrl: './add-new-employee-card.component.html',
  styleUrls: ['./add-new-employee-card.component.scss']
})
export class AddNewEmployeeCardComponent implements OnInit {
  newEmployeeDetails: FormGroup;
  userDto = new UserDTO();
  jobTitles = [];
  departments = [];
  filteredCareerManager: Observable<any[]>;
  filteredTalentBuddy: Observable<any[]>;
  rolesList: string[] = [];
  email: AbstractControl;
  emailError: string;
  photo: any;
  flagForError = false;
  base64textString: any;
  imageError: string;
  binaryImgString: string;
  allTitleList = [];
  probationPeriods = [];
  genders = [];
  locations: string[];
  probationPeriodDetails: any = [];
  saveBtnLabel = 'Save';
  countrySelectEvent: any;
  geoLocations: any = [];
  employmentTypes: any = [];
  maritalStatusTypes: any = [];
  baseLocation: any;
  officeLocation: any;
  isValidEmail = true;
  hasRoleBased = false;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  isUserEditingOwnDetais: boolean = false;
  isSuperAdmin: boolean = false;
  isHrAdmin: boolean=false;
  isEmployeeAdmin: boolean = false;
  isSuperAdminViwer: boolean=false;

  constructor(
    public userService: UserService,
    private userDetailService: UserDetailsService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private careerManagerService: CareerManagerTeamService,
    private titleService: TitleService,
    private probationService: AddEmployeeProbationService,
    private dialogRef: MatDialogRef<AddNewEmployeeCardComponent>,
    private toast: ToastrService,
    private restApi: RestAPIService,
    private userUrl: UserUrlService,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {

  }

  ngOnInit(): void {
    if(this.data[0]){
      this.checkForRoles();
    }
    this.setRoleList();
    
    
    if (this.data[0]?.id) {
      if (this.data[0].id === this.userDetailService.getUserDetails().id)
        this.isUserEditingOwnDetais = true;
    }
    this.setform();
    this.getUsers();
    // Binding Edit Data to Form
    this.setFormData();
    this.processEnumValuesToArray();
    // on Career Manager/Talent Buddy search
    this.getfilters();
    this.displayFnCareerManager(new UserDTO());
  }

  checkForRoles() {
    for (let authority of (this.data[0]?.authorities)) {
      if (authority === 'ROLE_SUPER_ADMIN') {
        this.isSuperAdmin = true;
      }
    }
    if(this.userDetailService.hasRole([
      'ROLE_SUPER_ADMIN'
    ])){
      this.isSuperAdmin=false;
    }

    this.isSuperAdminViwer = this.userDetailService.hasRole([
      'ROLE_SUPER_ADMIN',
    ]);

    this.isHrAdmin = this.userDetailService.hasRole([
      'ROLE_HUMAN_RESOURCE',
    ]);

    this.isEmployeeAdmin = this.userDetailService.hasRole([
      'ROLE_EMPLOYEE_ADMIN'
    ]);

  }

  setform() {
    this.hasRoleBased = this.userDetailService.hasRole([
      'ROLE_HUMAN_RESOURCE',
      'ROLE_SUPER_ADMIN',
      'ROLE_EMPLOYEE_ADMIN'
    ]);
    this.newEmployeeDetails = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      department: ['', [Validators.required]],
      email: [
        '',
        ([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,}$'),
        ]),
      ],
      mobileNo: ['', [Validators.required]],
      jobTitle: ['', [Validators.required]],
      careerManager: [''],
      dateOfJoining: ['', [Validators.required]],
      roles: [['Role User'], [Validators.required]],
      probationPeriod: ['', [Validators.required]],
      confirmationDate: ['', [Validators.required]],
      imageBase: [''],
      gender: ['', [Validators.required]],
      talentBuddy: [''],
      geoLocation: ['', [Validators.required]],
      baseLocation: ['', [Validators.required]],
      officeLocation: ['', ([Validators.required, Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9+#. -]*$')])],
      employmentType: ['', [Validators.required]],
      alternateMobileNo: [''],
      maritalStatus: [''],
      middleName: [''],
      dob: [''],

    });
    if (this.data[1] === 'active-user') {
      // Add validators to the specific form controls
      this.newEmployeeDetails.get('careerManager').setValidators(Validators.required);
      // Add validators to other form controls as needed
    }
  }
  getUsers() {
    this.userService.getAllUserForDropDown().subscribe((res) => {
      this.userService.setUsersToPopulateCareerManagerAndTalentBuddyLists(
        res.body
      );
    });
  }
  getfilters() {
    this.filteredCareerManager = this.newEmployeeDetails
      .get('careerManager')
      .valueChanges.pipe(
        startWith(''),
        map((state) => this.filterManager(state))
      );

    this.filteredTalentBuddy = this.newEmployeeDetails
      .get('talentBuddy')
      .valueChanges.pipe(
        startWith(''),
        map((state) => this.filterManager(state))
      );
  }
  getTitleData() {
    this.titleService.getAllTitleList().subscribe((data) => {
      data.body.forEach((item) => {
        this.allTitleList.push(item);
      });
    });
  }

  jobTitleList(data) {
    const probationValue = this.probationPeriodDetails.filter(
      (x) => x.jobTitle === data
    )[0]?.probationPeriod;
    this.newEmployeeDetails
      .get('probationPeriod')
      .setValue(probationValue ? probationValue : '');
  }

  setDefaultImg() {
    this.photo = false;
    this.binaryImgString = '';
    this.newEmployeeDetails.patchValue({
      imageBase: null,
    });
  }

  checkError = (controlName: string, errorName: string) => {
    return this.newEmployeeDetails?.controls[controlName]?.hasError(errorName);
  };

  setRoleList(){
    if(this.isSuperAdminViwer){
      for (const authoritiesList of Object.values(AuthoritiesConstants)) {
        if (isNaN(Number(authoritiesList))) {
          this.rolesList.push(authoritiesList);
        }
      }
    }else if(this.isHrAdmin){
      for (const authoritiesList of Object.values(AuthoritiesConstantsForHr)) {
        if (isNaN(Number(authoritiesList))) {
          this.rolesList.push(authoritiesList);
        }
      }
    }else if(this.isEmployeeAdmin){
      for (const authoritiesList of Object.values(AuthoritiesConstantsForEmployeeAdmin)) {
        if (isNaN(Number(authoritiesList))) {
          this.rolesList.push(authoritiesList);
        }
      }
    }else{
      for (const authoritiesList of Object.values(AuthoritiesConstantsFoNewUser)) {
        if (isNaN(Number(authoritiesList))) {
          this.rolesList.push(authoritiesList);
        }
      }
    }
  }

  processEnumValuesToArray() {
    for (const jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle))) {
        this.jobTitles.push(jobTitle);
      }
    }
    

    for (const department of Object.values(Departments)) {
      if (isNaN(Number(department))) {
        this.departments.push(department);
      }
    }

    for (const probationPeriod of Object.values(ProbationPeriod)) {
      if (Number(probationPeriod)) {
        this.probationPeriods.push(probationPeriod);
      }
    }

    for (const gender of Object.values(Gender)) {
      if (isNaN(Number(gender))) {
        this.genders.push(gender);
      }
    }

    for (const geoLocation of Object.values(GeoLocation)) {
      if (isNaN(Number(geoLocation))) {
        this.geoLocations.push(geoLocation);
      }
    }
    for (const employmentType of Object.values(EmployementType)) {
      if (isNaN(Number(employmentType))) {
        this.employmentTypes.push(employmentType);
      }
    }
    for (const maritalStatus of Object.values(RelationshipStatus)) {
      if (isNaN(Number(maritalStatus))) {
        this.maritalStatusTypes.push(maritalStatus);
      }
    }
  }

  readURL(event: Event): void {
    this.photo = false;
    this.imageError = '';

    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 1000000;

      if (file.size > max_size) {
        this.imageError =
          'Maximum allowed size is ' + max_size / 1000000 + 'MB';
        return;
      }

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let sourceX, sourceY, sourceWidth, sourceHeight;

          if (img.width > img.height) {
            const diff = (img.width - img.height) / 2;
            sourceX = diff;
            sourceY = 0;
            sourceWidth = img.height;
            sourceHeight = img.height;
          } else {
            const diff = (img.height - img.width) / 2;
            sourceX = 0;
            sourceY = diff;
            sourceWidth = img.width;
            sourceHeight = img.width;
          }
          const targetSize = Math.min(img.width, img.height);
          canvas.width = targetSize;
          canvas.height = targetSize;
          ctx.drawImage(
            img,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            0,
            0,
            targetSize,
            targetSize
          );
          const croppedImageURL = canvas.toDataURL('image/jpeg');

          this.photo = true;
          this.binaryImgString = croppedImageURL;
          this.base64textString = btoa(this.binaryImgString);
          this.newEmployeeDetails.patchValue({
            imageBase: this.binaryImgString.split(',')[1],
          });
        };
      };
      reader.readAsDataURL(file);
    }
  }

  checkManagerValidity() {
    const selectedManager = this.newEmployeeDetails.get('careerManager').value;
    this.filteredCareerManager.subscribe(managers => {
      const managerExists = managers.some(manager => manager === selectedManager);
      if (!managerExists && selectedManager !== '') {
        this.newEmployeeDetails.get('careerManager').setErrors({ notInList: true });
      } else if (this.data[1] != 'active-user') {
        this.newEmployeeDetails.get('careerManager').setErrors(null);
      }
    });
  }
  checkTalentValidity() {
    const selectedBuddy = this.newEmployeeDetails.get('talentBuddy').value;
    this.filteredTalentBuddy.subscribe(buddys => {
      const managerExists = buddys.some(buddy => buddy === selectedBuddy);
      if (!managerExists && selectedBuddy !== '') {
        this.newEmployeeDetails.get('talentBuddy').setErrors({ notInList: true });
      } else {
        this.newEmployeeDetails.get('talentBuddy').setErrors(null);
      }
    });
  }
  onEmailTextTyped(event) {
    if (event !== "") {
      this.userService.validateEmail(event).subscribe(
        (res) => {
          this.isValidEmail = true;
        },
        (error) => {
          this.isValidEmail = false;
          if (error.error.errorKey == 'emailexists') {
            this.setFormControlError(
              'email',
              'InvalidEmail',
              'Email already Exist'
            );
          } else {
            this.setFormControlError('email', 'InvalidEmail', 'required');
          }
        }
      );
    } else {
      this.isValidEmail = true;
    }
  }

  setFormData() {

    if (this.data[0]) {
      this.newEmployeeDetails
        .get('firstName')
        .setValue(this.data[0].firstName ? this.data[0].firstName : '');

      this.newEmployeeDetails
        .get('lastName')
        .setValue(this.data[0].lastName ? this.data[0].lastName : '');
      this.newEmployeeDetails
        .get('middleName')
        .setValue(this.data[0].middleName ? this.data[0].middleName : '');
      const jobTitle = this.getEnumValueByEnumKey(
        JobTitles,
        this.data[0].jobTitle
      );
      this.newEmployeeDetails
        .get('jobTitle')
        .setValue(this.data[0].jobTitle ? jobTitle : '');

      const gender = this.getEnumValueByEnumKey(Gender, this.data[0].gender);

      this.newEmployeeDetails
        .get('gender')
        .setValue(this.data[0].gender ? gender : '');

      const department = this.getEnumValueByEnumKey(
        Departments,
        this.data[0].department
      );
      this.newEmployeeDetails
        .get('department')
        .setValue(this.data[0].department ? department : '');

      const geoLocation = this.getEnumValueByEnumKey(
        GeoLocation,
        this.data[0].geoLocation
      );
      this.newEmployeeDetails
        .get('geoLocation')
        .setValue(this.data[0].geoLocation ? geoLocation : '');
      if (geoLocation != '')
        this.onGeoLocationChange(this.data[0].geoLocation);
      const employmentType = this.getEnumValueByEnumKey(
        EmployementType,
        this.data[0].employmentType
      );
      this.newEmployeeDetails
        .get('employmentType')
        .setValue(this.data[0].employmentType ? employmentType : '');

      const maritalStatus = this.getEnumValueByEnumKey(
        RelationshipStatus,
        this.data[0].relationshipStatus
      );
      this.newEmployeeDetails
        .get('maritalStatus')
        .setValue(this.data[0].relationshipStatus ? maritalStatus : '');

      const tempauthorities = this.getauthorityValueByEnumKeyArray(
        AuthoritiesConstants,
        this.data[0].authorities
      );
      this.newEmployeeDetails
        .get('roles')
        .setValue(this.data[0].authorities ? tempauthorities : '');

      this.newEmployeeDetails
        .get('email')
        .setValue(this.data[0].email ? this.data[0].email : '');

      this.newEmployeeDetails
        .get('mobileNo')
        .setValue(this.data[0].mobileNumber ? this.data[0].mobileNumber : '');
      this.newEmployeeDetails.controls.mobileNo.setValidators([
        Validators.maxLength(
          this.newEmployeeDetails.get('mobileNo').value.length
        ),
      ]);
      this.newEmployeeDetails
        .get('alternateMobileNo')
        .setValue(this.data[0].alternateMobileNumber ? this.data[0].alternateMobileNumber : '');
      if (this.userService.usersDto) {
        if (this.data[0].careerManagerId) {
          const careerManagerRecord = this.userService.usersDto.find((obj) => {
            return obj.id == this.data[0].careerManagerId;
          });
          this.newEmployeeDetails
            .get('careerManager')
            .setValue(new User(careerManagerRecord));
        }

        if (this.data[0].talentBuddyId) {
          const talentBuddyRecord = this.userService.usersDto.find((obj) => {
            return obj.id == this.data[0].talentBuddyId;
          });
          this.newEmployeeDetails
            .get('talentBuddy')
            .setValue(new User(talentBuddyRecord));
        }
      } else {
        this.setCmandBuddy(this.data[0]);
      }

      this.newEmployeeDetails
        .get('dateOfJoining')
        .setValue(this.data[0].joiningDate ? new Date(this.data[0].joiningDate) : '');
      this.newEmployeeDetails
        .get('dob')
        .setValue(this.data[0].dateOfBirth ? new Date(this.data[0].dateOfBirth) : '');
      const probationPeriod = this.getEnumValueByEnumKey(
        ProbationPeriod,
        this.getEnumKeyByEnumValue(
          ProbationPeriod,
          this.data[0].probationPeriod.toString()
        )
      );
      this.newEmployeeDetails
        .get('probationPeriod')
        .setValue(this.data[0].probationPeriod ? probationPeriod : '');

      this.newEmployeeDetails
        .get('confirmationDate')
        .setValue(
          this.data[0].confirmationDate ? new Date(this.data[0].confirmationDate) : ''
        );

      this.newEmployeeDetails
        .get('baseLocation')
        .setValue(this.data[0].baseLocation ? this.data[0].baseLocation : '');

      this.newEmployeeDetails
        .get('officeLocation')
        .setValue(this.data[0].officeLocation ? this.data[0].officeLocation : '');

      if (
        this.data[0].userImageThumbnail !== null &&
        this.data[0].userImageThumbnail.length > 0
      ) {
        this.photo = true;
        this.binaryImgString = this.data[0].userImageThumbnail;
      } else {
        this.photo = false;
      }

      this.newEmployeeDetails.controls.email.disable();
    }
    if (!this.hasRoleBased) {
      //Role user can edit photo, Current Location and Mobile number
      this.newEmployeeDetails.controls.firstName.disable();
      this.newEmployeeDetails.controls.lastName.disable();
      this.newEmployeeDetails.controls.department.disable();
      this.newEmployeeDetails.controls.jobTitle.disable();
      this.newEmployeeDetails.controls.gender.disable();
      this.newEmployeeDetails.controls.talentBuddy.disable();
      this.newEmployeeDetails.controls.careerManager.disable();
      this.newEmployeeDetails.controls.dateOfJoining.disable();
      this.newEmployeeDetails.controls.roles.disable();
      this.newEmployeeDetails.controls.probationPeriod.disable();
      this.newEmployeeDetails.controls.confirmationDate.disable();
      this.newEmployeeDetails.controls.geoLocation.disable();
      this.newEmployeeDetails.controls.baseLocation.disable();
      this.newEmployeeDetails.controls.employmentType.disable();
      this.newEmployeeDetails.controls.middleName.disable();
      // this.newEmployeeDetails.controls.dob.disable();
      // this.newEmployeeDetails.controls.maritalStatus.disable();
    }
  }
  setCmandBuddy(data) {
    this.restApi
      .get(this.userUrl.getPaginationUrl(1, 500, 'Asc'))
      .subscribe((res) => {
        this.userService.setUsers(res.body);
        this.userService.setUsersDto(res.body);
        if (this.data[0].careerManagerId) {
          const careerManagerRecord = this.userService.usersDto.find((obj) => {
            return obj.id == this.data[0].careerManagerId;
          });
          this.newEmployeeDetails
            .get('careerManager')
            .setValue(new User(careerManagerRecord));
        }

        if (this.data[0].talentBuddyId) {
          const talentBuddyRecord = this.userService.usersDto.find((obj) => {
            return obj.id == this.data[0].talentBuddyId;
          });
          this.newEmployeeDetails
            .get('talentBuddy')
            .setValue(new User(talentBuddyRecord));
        }
      });
  }

  onSubmit() {
    if (this.newEmployeeDetails.valid) {
      this.userDto.createdBy = this.userDetailService.getUserDetails().id;
      this.userDto.createdDate = new Date();
      this.userDto.lastModifiedBy = this.userDto.createdBy;
      this.userDto.lastModifiedDate = this.userDto.lastModifiedDate;
      this.userDto.firstName = this.newEmployeeDetails.get('firstName').value;
      this.userDto.lastName = this.newEmployeeDetails.get('lastName').value;
      this.userDto.middleName = this.newEmployeeDetails.get('middleName').value;
      this.userDto.dateOfBirth = this.newEmployeeDetails.get('dob').value;
      this.userDto.alternateMobileNumber = this.newEmployeeDetails.get('alternateMobileNo').value;

      this.userDto.email = this.newEmployeeDetails.get('email').value;
      this.userDto.jobTitle = this.getEnumKeyByEnumValue(
        JobTitles,
        this.newEmployeeDetails.get('jobTitle').value
      );
      this.userDto.gender = this.getEnumKeyByEnumValue(
        Gender,
        this.newEmployeeDetails.get('gender').value
      );
      this.userDto.talentBuddyId =
        this.newEmployeeDetails.get('talentBuddy').value.userID;
      if (this.userDto.talentBuddyId === undefined) {
        this.userDto.talentBuddyId =
          this.newEmployeeDetails.get('talentBuddy').value.id;
      }
      this.userDto.authorities = this.getauthoritiesKeyByEnumValueArray(
        AuthoritiesConstants,
        this.newEmployeeDetails.get('roles').value
      );
      this.userDto.login = this.userDto.email;
      this.userDto.microsoftId = 'asldkfjasdgnak23rafa9';
      this.userDto.mobileNumber = this.newEmployeeDetails.get('mobileNo').value;
      this.userDto.langKey = 'en';
      this.userDto.careerManagerId =
        this.newEmployeeDetails.get('careerManager').value.userID;
      if (this.userDto.careerManagerId === undefined) {
        this.userDto.careerManagerId =
          this.newEmployeeDetails.get('careerManager').value.id;
      }
      this.userDto.department = this.getEnumKeyByEnumValue(
        Departments,
        this.newEmployeeDetails.get('department').value
      );
      this.userDto.joiningDate =
        this.newEmployeeDetails.get('dateOfJoining').value;
      this.userDto.probationPeriod = parseInt(
        this.newEmployeeDetails.get('probationPeriod').value
      );
      this.userDto.confirmationDate =
        this.newEmployeeDetails.get('confirmationDate').value;
      this.userDto.geoLocation = this.getEnumKeyByEnumValue(
        GeoLocation,
        this.newEmployeeDetails.get('geoLocation').value
      );
      this.userDto.employmentType = this.getEnumKeyByEnumValue(
        EmployementType,
        this.newEmployeeDetails.get('employmentType').value
      );
      this.userDto.relationshipStatus = this.getEnumKeyByEnumValue(
        RelationshipStatus,
        this.newEmployeeDetails.get('maritalStatus').value
      );
      this.userDto.baseLocation =
        this.newEmployeeDetails.get('baseLocation').value;
      this.userDto.officeLocation =
        this.newEmployeeDetails.get('officeLocation').value;

      if (this.photo) {
        this.newEmployeeDetails.patchValue({
          imageBase: this.binaryImgString.split(',')[1],
        });
        if (
          this.data[0] &&
          this.newEmployeeDetails.get('imageBase').value ===
          this.data[0].userImageThumbnail
        ) {
          this.userDto.userImage = 'Unchanged';
        } else {
          this.userDto.userImage =
            this.newEmployeeDetails.get('imageBase').value;
        }
      } else {
        this.userDto.userImage = '';
      }

      if (this.data[0]) {
        if (this.data[0].id) {
          this.userDto.id = this.data[0].id;
          this.userDto.employeeId = this.data[0].employeeId;
          this.userDto.activated = this.data[0].activated;
        }
        this.saveBtnLabel = 'Saving...';
        this.userService.updateUser(this.userDto).subscribe(
          (res) => {
            this.userService.getUser('Asc');
            if (this.data[0].id == this.userDetailService.getUserDetails().id) this.userDetailService.setImage(this.userDto.userImage);
            this.toast.success('User Data Edited', 'Success');
            this.dialogRef.close();

            this.saveBtnLabel = 'Save';
          },
          (e) => { }
        );
      }
      else {
        this.saveBtnLabel = 'Saving...';
        this.userService.addUser(this.userDto).subscribe((res) => {
          this.userService.getUser('Asc');
          this.toast.success('New User Created', 'Success');
          this.dialogRef.close();
          this.saveBtnLabel = 'Save';
        });
      }
    } else {
      this.notificationService.invalidFormData();
    }
  }

  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.newEmployeeDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newEmployeeDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newEmployeeDetails
      .get(controlName)
      .setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  displayFnCareerManager(user): string {
    return user && (user.firstName || user.lastName)
      ? user.firstName + ' ' + user.lastName
      : '';
  }

  filterManager(name: string) {
    if (typeof name !== 'object') {
      return this.userService.usersDtoForCMTBList.filter(
        (user) =>
          user.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
          user.lastName.toLowerCase().indexOf(name.toLowerCase()) === 0
      );
    } else {
      return;
    }
  }

  getJobTitle(item) {
    if (isNaN(Number(item))) {
      return item;
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }

  getauthoritiesKeyByEnumValueArray(myEnum, enumValue) {
    return Object.keys(myEnum).filter((k) => enumValue.includes(myEnum[k]));
  }

  getauthorityValueByEnumKeyArray(myEnum, enumKeyArr) {
    return enumKeyArr ? enumKeyArr.map((k) => myEnum[k]) : '';
  }

  onProbationPeriodSelect(event: any): void {
    const dateOfJoining = this.newEmployeeDetails.get('dateOfJoining').value;
    if (dateOfJoining) {
      this.updateConfirmationDate(dateOfJoining, parseInt(event));
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const probationPeriod = this.newEmployeeDetails.get('probationPeriod').value;
    if (probationPeriod) {
      this.updateConfirmationDate(event.value, probationPeriod);
    }
  }

  private updateConfirmationDate(date: Date, probationPeriod: number): void {
    this.newEmployeeDetails.get('confirmationDate').setValue(addDays(date, probationPeriod));
  }

  countryChangedEvent($event, name) {
    this.countrySelectEvent = $event;
    let fCname = this.newEmployeeDetails.get(name);
    fCname.setValidators([
      Validators.maxLength(this.countrySelectEvent.placeHolder.length),
    ]);
  }

  focusOutMobileField(name) {

    if (!this.newEmployeeDetails.get(name).value) {
      if (name === 'mobileNo') this.setFormControlError(name, 'ZeroLength', 'required');
    } else if (
      this.newEmployeeDetails.get(name).value.length !==
      this.countrySelectEvent.placeHolder.length
    ) {
      this.setFormControlError(name, 'InvalidNumber', 'Invalid Pattern');
    } else {
    }
  }

  onGeoLocationChange(geoLocation: string) {
    if (geoLocation != null) {
      this.userService.fetchBaseLocations(geoLocation.toLocaleUpperCase()).subscribe(res => {
        this.locations = res.body;
      });
    }
  }


  // Function to handle career manager selection
  async onCareerManagerSelected(event) {

    const selectedManager = event.option.value.userID;
    this.userService.checkPendingTaskForCM(this.data[0].careerManagerId, selectedManager, this.data[0].id).subscribe(res => {

      if (res.body.responseStatus) {
        // this.toast.success(res.body.responseMessage, "Success");
      }
      else {

        if (this.data[0].careerManagerId) {
          const careerManagerRecord = this.userService.usersDto.find((obj) => {
            return obj.id == this.data[0].careerManagerId;
          });
          this.newEmployeeDetails
            .get('careerManager')
            .setValue(new User(careerManagerRecord));
        }
        if (res.body.responseCode == "ERR002")
          this.toast.warning("Exception occure", "Failuare");
        else
          this.toast.warning(res.body.responseMessage, "Failuare");
      }

    })
  }
}  