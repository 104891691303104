<div class="container-fluid">
  <div class="row import-dasboard-attendance align-items-center">
      <div class="col-6">
          <label class="upload_text mt-4">{{this.headingText}}</label>
      </div>
      <div class="col-6 d-flex justify-content-end">
          <img src="assets/icons/dashboard-icons/close-pink-icon.svg" (click)="closeDialog()">
      </div>
  </div>
    <!-- Create Select File button and add readFile function to display the selected file name on screen -->
     <!-- Select Base Location-->
    <div class="row margin-top" *ngIf="this.data.hideReupload==false">
      <div class="col-6">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>
            <span class="common-heading">Base location</span>
          </mat-label>
          <mat-select [(ngModel)]="selectedBaseLocation">
            <mat-option *ngFor="let baseLocation of baseLocations" [value]="baseLocation">
              {{ baseLocation | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="baseLocations.length === 0">Base Locations are Empty!</div>
      </div>
      <div class="col-6">
        <div>
          <button class="upload_btn" (click)="fileInput.click()">
            {{ uploadBtnLabel }}
          </button>
          <input
            type="file"
            id="my_file"
            style="display: none"
            accept=".xlsx"
            (change)="readFile($event.target.files)"
            #fileInput
          />
        </div>
      </div>
    </div>
    <div class="row margin-top justify-content-center" *ngIf="this.data.hideReupload==true">
        <div>
          <button class="upload_btn" (click)="fileInput.click()">
            {{ uploadBtnLabel }}
          </button>
          <input
            type="file"
            id="my_file"
            style="display: none"
            accept=".xlsx"
            (change)="readFile($event.target.files)"
            #fileInput
          />
        </div>
    </div>
    <!-- Select File-->
    <div>
        
      <!-- Display file name is file is selected, else display no file uploaded.
      Display upload button only when a file is selected and upon clicking it, 
      invoke uploadFile function 
      -->
      <div *ngIf="fileName" class="file_name center">
        {{ fileName }}
      </div>
      <div class="row">
      </div>
      <div *ngIf="!data.hideReupload && !fileName && (!isReuploading)">
        <button  (click)="changeToReupload(fileInput)" class="reupload-text">
          {{ reuploadText }}
        </button>
      </div>
      <div class="button-container">
        <button
          class="upload_btn"
          (click)="uploadFile()"
          type="submit"
          *ngIf="fileName !== 'Please wait...'"
          [disabled]="!isSelected"
        >
          {{importText}}
        </button>
      </div>   

      <div>
        <label style="color: red" class="center" *ngIf="badFileFormat">
          {{this.errorMessage}}
        </label>
      </div>
    </div>
  </div>
